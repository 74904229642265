/* This does the big font thingy idk men */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');


html,
body {
  color: #fff;
  background: #0E0E10;
  padding: 0;
  margin: 0;
  font: 300 1em 'Montserrat', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
